<template>
  <div class="statement">
    <div class="container">
      <div class="about-title">
        <h1 class="primary-title">法律声明</h1>
        <h2 class="secondary-title">Legal Statement</h2>
      </div>
      <div class="about-content">
        <div class="about-item-content">
          <div class="about-item-title">版权声明</div>
          <div class="about-item-body">
            <p>
              上海嗨喵网络科技有限公司（以下简称嗨喵科技）对其发行的或与合作伙伴共同发行的作品享有版权，受各国版权法及国际版权公约的保护。
            </p>
            <p>
              对于上述版权内容，超越合理使用范畴、并未经本公司书面许可的使用行为，我公司均保留追究法律责任的权利
            </p>
          </div>
        </div>
        <div class="about-item-content">
          <div class="about-item-title">商标声明</div>
          <div class="about-item-body">
            <p>
              嗨喵科技公司对“嗨喵科技、Joymew、嗨猫形象、hudongmiao、嗨喵助手”等文字或形象均进行了商标注册保护，嗨喵科技公司的注册商标信息，可见诸于各国公开的商标注册信息中。
            </p>
            <p>
              未经嗨喵科技公司的许可而使用上述商标，可能会侵犯嗨喵科技公司的注册商标权，对于涉嫌侵犯嗨喵科技公司注册商标权的行为，我公司将保留追究法律责任的权利。
            </p>
          </div>
        </div>
        <div class="about-item-content">
          <div class="about-item-title">相关法律</div>
          <div class="about-item-body">
            <p>《中华人民共和国刑法》(节录)</p>
            <p>《中华人民共和国商标法》</p>
            <p>《全国人民代表大会常务委员会关于维护互联网安全的决定》</p>
            <p>《计算机信息网络国际联网安全保护管理办法》</p>
            <p>《计算机软件保护条例》</p>
          </div>
        </div>
        <div class="about-item-content">
          <div class="about-item-title">网站标识</div>
          <div class="about-item-body">
            <p>
              www.hudongmiao.com、www.joymew.com等皆为嗨喵科技公司网站域名，用户欲了解嗨喵科技公司网站的备案详情，请点击网站上的工商及网安标志：
            </p>
            <p>
              <img src="@/assets/image/tp1.png" alt="" />&nbsp;&nbsp;<img
                src="@/assets/image/tp2.png"
                alt=""
              />
            </p>
            <p>
              本公司郑重提醒广大嗨喵科技用户，切勿登录访问仿冒嗨喵科技的不法网站，以免上当受害。
            </p>
          </div>
        </div>
      </div>
    </div>
    <myFooter />
  </div>
</template>

<script>
import myFooter from '@/components/myFooter.vue';

export default {
  name: 'statement',
  metaInfo: {
    title: '嗨喵悦动 - 嗨喵悦动登录_嗨喵悦动大屏互动',
    meta: [
      {
        name: 'keywords',
        content:
          '嗨喵悦动,嗨喵悦动大屏互动,嗨喵悦动官网,嗨喵悦动登录,微信墙,微信互动,现场互动,嗨现场',
      },
      {
        name: 'description',
        content:
          '嗨喵悦动是一款免费便捷炫酷的活动大屏幕现场互动软件，适用于企业年会、酒吧、婚礼、商业庆典峰会等活动现场，包括微信上墙送祝福、扫码签到、抽奖投票、摇一摇红包雨等有趣的大屏互动游戏，让活动更精彩！',
      },
    ],
  },
  components: { myFooter },
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {},
};
</script>
<style lang="less" scoped>
.banner {
  width: 100%;
  position: relative;
}

.banner > .img {
  width: 100%;
  height: 50%;
  background-color: #f9f9f9;
  vertical-align: middle;
  border: 0;
}

.about-title {
  top: 15%;
  padding: 20px 50px;
  width: 100%;
  color: #000;
  position: relative;
}

.primary-title {
  font-size: 40px;
}

.secondary-title {
  margin-top: 10px;
  padding-left: 30px;
  font-size: 20px;
  font-weight: 500;
  font-family: "Times New Roman", serif;
}

.container {
  position: relative;
}

.about-content {
  margin: 0px auto 0;
  padding: 30px 50px;
  width: 100%;
  max-width: 1090px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 20px 15px -5px #d3cfc5;
}

.about-item-content {
  margin-top: 10px;
}

.about-item-title {
  color: #ff4141;
  font-size: 16px;
  margin-bottom: 20px;
  font-weight: bolder;
}

.about-item-body {
  margin-bottom: 30px;
  line-height: 1.5;
  text-indent: 2em;
}

p {
  margin: 0 0 10px;
}
</style>
